
import PageMainHeading from "@/components/Layout/PageMainHeading.vue";
import ShadowControl from "@/components/Form/ShadowControl.vue";
import { mapActions, useStore } from "vuex";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import DataPrice from "@/components/Table/ts/instances/data-price/DataPrice.vue";
import { computed } from "@vue/reactivity";
import { defineComponent, reactive, watch } from "vue";
import { IObjectKeys } from "@/interfaces/IObjectKeys";
import {
  IUseBaseAppLayout,
  useBaseAppLayout
} from "@/hooks/layout/useBaseAppLayout";
import { sortOrders } from "@/enums/main/sortOrders";
import AppTableController from "@/components/Table/ts/AppTableController.vue";
import { useDataPrice } from "@/hooks/data-price/useDataPrice";
import EditDataPriceDialog from "@/components/ui/Modal/Dialog/instances/EditDataPriceDialog.vue";

const DataPriceSortKeys = {
  title: "title"
};

export const dataPriceSortKeys: IObjectKeys = [DataPriceSortKeys.title];

export default defineComponent({
  name: "PayAsYouGo",
  components: {
    AppTableController,
    ShadowControl,
    PageMainHeading,
    DataPrice,
    EditDataPriceDialog
  },

  setup() {
    const store = useStore();

    const viewConfiguration: ITableViewConfiguration = reactive({
      page: 1,
      perPage: 10
    });

    // Page layout refs
    const layoutRefs: IUseBaseAppLayout = useBaseAppLayout({
      initialSearchQuery: "",
      filterOptions: [],
      onFiltersChange: () => requestDataPriceHandler(true, fullFilter.value),
      onChangeSearchQuery() {
        changeViewConfigurationHandler({
          page: 1,
          perPage: viewConfiguration.perPage
        });
      }
    });

    watch(viewConfiguration, () =>
      requestDataPriceHandler(true, fullFilter.value)
    );

    const { searchQuery } = layoutRefs;

    const fullFilter = computed(() => {
      return {
        filter: searchQuery.value
      };
    });

    const options = {
      filter: fullFilter.value,
      viewConfiguration
    };
    const {
      requestDataPriceHandler,
      changeViewConfigurationHandler,
      editDataPrice
    } = useDataPrice(options);
    requestDataPriceHandler();
    return {
      ...layoutRefs,
      viewConfiguration,
      searchQuery,
      dataPriceList: computed(() => store.getters.dataPriceList),
      totalDataPrice: computed(() => store.getters.totalDataPrice),
      requestDataPriceHandler,
      changeViewConfigurationHandler,
      editDataPrice
    };
  },
  data() {
    return {
      sortConfig: {
        byName: { order: sortOrders.turnOff, keyName: "fullName" }
      },
      isLoading: false,
      editDataPriceDialogOpened: false,
      editData: null
    };
  },
  methods: {
    ...mapActions(["setAppLoading", "showErrorNotification"]),
    searchHandler(query = "") {
      this.searchQuery = query.trim().toLowerCase();
    },
    editDataPriceHandler(id: number) {
      this.editData = this.dataPriceList.find(
        (country: any) => country.id === id
      );
      this.toggleEditDialog();
    },
    toggleEditDialog(status = true) {
      this.editDataPriceDialogOpened = status;
    },

    async endEditDataPrice(data: any): Promise<boolean> {
      this.isLoading = true;
      const success = await this.editDataPrice({
        ...data
      });

      this.isLoading = false;
      if (success) {
        await this.requestDataPriceHandler();
        this.toggleEditDialog(false);
      }
      return success;
    },

    showNotification(message: string): void {
      this.$store.dispatch("showErrorNotification", message);
    }
  }
});
