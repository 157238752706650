import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "d-flex justify-content-end page-heading-toolbar" }
const _hoisted_3 = { class: "page-heading-toolbar--col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditDataPriceDialog = _resolveComponent("EditDataPriceDialog")!
  const _component_ShadowControl = _resolveComponent("ShadowControl")!
  const _component_PageMainHeading = _resolveComponent("PageMainHeading")!
  const _component_DataPrice = _resolveComponent("DataPrice")!
  const _component_AppTableController = _resolveComponent("AppTableController")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_EditDataPriceDialog, {
        "edit-data": _ctx.editData,
        opened: _ctx.editDataPriceDialogOpened,
        loading: _ctx.isLoading,
        "end-create": _ctx.endEditDataPrice,
        onHide: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleEditDialog(false)))
      }, null, 8, ["edit-data", "opened", "loading", "end-create"]),
      _createVNode(_component_PageMainHeading, { title: "Countries Data Price" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_ShadowControl, {
                id: "dataPrice-search",
                class: "search-control",
                icon: "search",
                placeholder: "Search...",
                name: "dataPrice-search",
                "input-timeout": true,
                onChange: _ctx.searchHandler
              }, null, 8, ["onChange"])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_AppTableController, {
      "show-table": !!_ctx.dataPriceList.length,
      "search-query": _ctx.searchQuery,
      "empty-key": "dataPrice",
      "empty-icon": "credits"
    }, {
      default: _withCtx(() => [
        (_ctx.dataPriceList.length)
          ? (_openBlock(), _createBlock(_component_DataPrice, {
              key: 0,
              "data-price-list": _ctx.dataPriceList,
              "total-rows-count": _ctx.totalDataPrice,
              "parent-view-configuration": _ctx.viewConfiguration,
              sort: _ctx.sortConfig,
              onEdit: _ctx.editDataPriceHandler,
              onChangeViewConfiguration: _ctx.changeViewConfigurationHandler
            }, null, 8, ["data-price-list", "total-rows-count", "parent-view-configuration", "sort", "onEdit", "onChangeViewConfiguration"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["show-table", "search-query"])
  ], 64))
}