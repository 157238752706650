
import { ComputedRef, defineComponent, PropType, computed } from "vue";
import {
  cellCountry,
  ITableBodyRow,
  ITableHeadCell
} from "@/components/Table/ts/interfaces/TableStructure";
import { sortItem, sortOrders } from "@/enums/main/sortOrders";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import { userId } from "@/store/interfaces/auth/common";
import AppTable from "@/components/Table/ts/AppTable.vue";
import { useBaseTable } from "@/hooks/tables/useTable";
import { ICountriesDataPrice } from "@/store/interfaces/data-price/IDataPrice";

type tableHeadCells = ITableHeadCell[];

export type dataPriceTableSort = {
  byTitle: sortItem;
};

export default defineComponent({
  name: "DataPrice",
  components: { AppTable },
  props: {
    totalRowsCount: {
      type: Number,
      required: true
    },

    dataPriceList: {
      type: Array as PropType<ICountriesDataPrice[]>,
      required: true
    },

    sort: {
      type: Object as PropType<dataPriceTableSort>,
      required: true
    },

    parentViewConfiguration: {
      type: Object as PropType<ITableViewConfiguration>,
      default: () => ({
        page: 1,
        perPage: 10
      })
    }
  },
  emits: {
    edit: null,
    changeViewConfiguration: null
  },
  setup(props, { emit }) {
    const initialSortOptions: dataPriceTableSort = {
      byTitle: {
        order: sortOrders.asc,
        keyName: "name",
        keyNameInModel: "name"
      }
    };

    // row actions
    const dataPriceEditHandler = (id: userId) => emit("edit", id);

    // table model
    const dataPriceList: ComputedRef<ICountriesDataPrice[]> = computed(
      () => props.dataPriceList
    );

    const tableRefs = useBaseTable({
      initialViewConfiguration: { ...props.parentViewConfiguration },
      initialSortOptions,
      model: dataPriceList,
      generateRows(dataPriceList: ICountriesDataPrice[]): ITableBodyRow[] {
        return dataPriceList.map(
          (price: ICountriesDataPrice): ITableBodyRow => {
            const { id, name, icon, dataPrice } = price;

            return {
              actions: [
                {
                  text: "Edit",
                  onClick: (): void => {
                    dataPriceEditHandler(id);
                  }
                }
              ],

              cells: [
                {
                  country: {
                    name,
                    icon
                  } as cellCountry
                },
                {
                  label: dataPrice?.amount ? dataPrice.amount.toString() : "-"
                }
              ]
            };
          }
        );
      },

      onViewConfigurationChanged(
        viewConfiguration: ITableViewConfiguration
      ): void {
        // get data by parent component
        emit("changeViewConfiguration", viewConfiguration);
      }
    });

    const {
      rows,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort
    } = tableRefs;

    return {
      rows,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort
    };
  },

  computed: {
    tableHead(): tableHeadCells {
      const { byTitle } = this.currentSort;
      return [
        {
          size: "fluid",
          sort: {
            keyName: byTitle.keyName,
            order: byTitle.order
          },

          label: "Title",
          id: "country-title"
        },
        {
          size: "sm",
          label: "Amount",
          id: "data-price-amount"
        },
        {
          size: "xxs",
          label: "",
          id: "data-price-actions"
        }
      ];
    }
  }
});
