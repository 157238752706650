import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-4 pt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_AppInputsValidationProvider = _resolveComponent("AppInputsValidationProvider")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, _mergeProps({
    title: _ctx.title,
    opened: _ctx.opened
  }, _ctx.$attrs, { onHide: _ctx.hide }), {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createVNode(_component_AppInputsValidationProvider, {
          "shake-tree": _ctx.parentTouched,
          onChangeErrorState: _ctx.onChangeErrorState
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_AppInput, {
                id: "edit-data-price",
                modelValue: _ctx.amount,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.amount) = $event)),
                "input-type": "material",
                label: "Data price by 1Mb",
                type: "number",
                "validation-type": "notEmptyString"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _: 1
        }, 8, ["shake-tree", "onChangeErrorState"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_AppButton, {
            type: "secondary",
            size: "xl",
            disabled: _ctx.validation,
            loading: _ctx.loading,
            onClick: _ctx.submit
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          }, 8, ["disabled", "loading", "onClick"])
        ])
      ], 32)
    ]),
    _: 1
  }, 16, ["title", "opened", "onHide"]))
}